import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { HashRouter, Routes, Route } from "react-router-dom";
import { useSessionStore } from "../Stores/SessionStore";
import LoginModal from "./Pages/LoginModal";
import RegistrationApproval from "./Pages/RegistrationApproval/RegistrationApproval";

export default function App() {
	const store = useSessionStore();
	const [showLoginModal, setShowLoginModal] = useState(false);

	useEffect(() => {
		if(store.UserIsLoggedIn === false) {
			setShowLoginModal(true);
		}
	}, [])

  	return	<Container>
				<HashRouter>
					<Routes>
						<Route path="RegistrationApproval/:customerId" element={ <RegistrationApproval></RegistrationApproval> }></Route>
					</Routes>
					<LoginModal show={ showLoginModal } onClose={ e => setShowLoginModal(false) }></LoginModal>
				</HashRouter>
	  		</Container>
}