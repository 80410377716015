import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";

const LoginModal = observer(function LoginModal(props) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showLoginError, setShowLoginError] = useState(false);

	const store = useSessionStore();

	async function handleLoginClicked() {
		var result = await store.Login(username, password);

		if((result && result.Success === false) || !result) {
			setShowLoginError(true);
		} else {
			props.onClose();
		}
	}

	function handleKeypress(key) {
		if(key === "Enter") {
			handleLoginClicked();
		}
	}

	return 	<Modal show={ props.show }>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label>Username</Form.Label>
							<Form.Control value={username} onChange={ e => setUsername(e.target.value)} onKeyPress={ e => handleKeypress(e.code)}></Form.Control>
						</Form.Group>
						<Form.Group className="mt-3">
							<Form.Label>Password</Form.Label>
							<Form.Control type="password" value={password} onKeyPress={ e => handleKeypress(e.code)} onChange={ e => setPassword(e.target.value)}></Form.Control>
						</Form.Group>
					</Form>
					{
						showLoginError &&
						<small className="text-danger">Invalid Username or Password</small>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ store.LoggingIn } onClick={ handleLoginClicked }>Login</Button>
					<Button disabled={ store.LoggingIn } onClick={ props.onClose }>Cancel</Button>
				</Modal.Footer>
			</Modal>
});

export default LoginModal;