import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { CustomersService } from "../Services/CustomersService";
import UsersService from "../Services/UsersService";

/**
 * @typedef Session
 * @type {object}
 * @property {string} BearerToken - The JWT Token used to authenticate the client.
 * @property { import('../Services/UsersService').User } User - The logged in user
 */

/**
 * @typedef Config
 * @type {object}
 * @property {string} apiUrl - The URL to the application's backend API.
 */

/** @type {SessionStore} */
var SessionStoreInstance = null;

const SessionStoreContext = createContext(null);

/**
 * @typedef SessionStore
 * @type {SessionStore}
*/
export class SessionStore {
    /**@type {Session} */ Session = null;
    LoggingIn = false;

    /**@param {Config} config */
    constructor(config) {
        //attempt to initialise the session from local storage
        this.GetStoredSession();

        //initialise services
		this.UsersService = new UsersService(config.apiUrl, this);
        this.CustomersService = new CustomersService(config.apiUrl, this);

        makeAutoObservable(this);
    }

    GetStoredSession() {
        var session = localStorage.getItem("Session");

        if(session) {
            this.Session = JSON.parse(session);
        } else {
            this.Session = null;
        }
    }

    get UserIsLoggedIn () {
        return this.Session !== null;
    }

    async SetLoggingIn(loggingIn) {
        runInAction(() => { this.LoggingIn = loggingIn; });
    }

	async Login(username, password) {
        this.SetLoggingIn(true);
        var result = await this.UsersService.Login(username, password);
        this.SetLoggingIn(false);

        if(result.Success) {
            localStorage.setItem("Session", JSON.stringify(result.Data));
            runInAction(() => { this.Session = result.Data; });
        }

        return result;
    }

    Logout () {
        localStorage.clear();
        this.Session = null;
    }
}

export function SessionStoreProvider(props) {
    if(SessionStoreInstance === null) {
        SessionStoreInstance = new SessionStore(props.config);
    }

    return <SessionStoreContext.Provider value={ SessionStoreInstance }>{ props.children }</SessionStoreContext.Provider>
}

/**
 * @returns {SessionStore}
 */
export function useSessionStore() {
    const context = useContext(SessionStoreContext);

    return context;
}