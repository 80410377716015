/**
 * @typedef RequestResult
 * @property {boolean} Success
 * @property {string} Message
 * @property {number} ResultCode
 * @property {T} Data
 * @template T
 */

/**
 * @typedef EnumerableRequestResult
 * @property {boolean} Success
 * @property {string} Message
 * @property {number} ResultCode
 * @property {Array<T>} Data
 * @property {number} Total
 * @template T
 */

/**
 * @typedef DownloadFile
 * @property {string} FileName
 * @property {string} FileContents
 * @property {string} MimeType
 */

/**
 * @typedef APIBase
 */
 export default class APIBase {

    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     */
    constructor(apiUrl, sessionStore) {
        this.APIUrl = apiUrl;
        this.SessionStore = sessionStore;
        console.log(this.APIUrl);
    }

    /**
     * @param {string} url The target URL for the fetch request
     * @param {string} method The method type
     * @param {object} body Object containing the bosy that will be serialised into JSON
     */
    async SendRequest(url, method, body) {
        var session = this.SessionStore ? this.SessionStore.Session : null;
        console.log("hello");
        try {
            var response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + (session ? session.BearerToken : "")
                },
                body: body ? JSON.stringify(body) : null
            });

            if(response.ok) {
                return await response.json();
            }
            else {
                if(response.status === 401) {
                    this.SessionStore.Logout();
                    return null;
                }
                else if(response.status === 500) {
                    return { Success: false, StatusCode: 500, Message: "An internal error occurred on the server, please contact support." };
                }
            }
        }
        catch(ex) {

        }

        return { Success: false, StatusCode: 500, Message: "The server did not respond to the request." };
    }

    /**
     * @param {string} endpoint The URL endpoint for the given method
     * @param {object} body The object that will be serialised into JSON and posted to the server
     */
    async Post (endpoint, body) {
        return await this.SendRequest(this.APIUrl + "/" + endpoint, "POST", body);
    }

    /**
     * @param {string} endpoint The URL endpoint for the given method
     */
    async Get(endpoint) {
        
        return await this.SendRequest(this.APIUrl + "/" + endpoint, "GET", null);
    }
}