import React, { useEffect, useState } from "react";
import { Field, Formik, Form as FormikForm, validateYupSchema } from "formik"
import { useSessionStore } from "../../../Stores/SessionStore"
import { useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import BusyIndicator from "../../Core/BusyIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import FileUpload from "./FileUpload";
import * as yup from 'yup';
import { validate } from 'luhn'

export default function RegistrationApproval() {
	const store = useSessionStore();
	const params = useParams();

	const [details, setDetails] = useState(null);
	const [error, setError] = useState("");
	const [approved, setApproved] = useState(false);
	const [changesSaved, setChangesSaved] = useState(false);
	const [approvingCustomer, setApprovingCustomer] = useState(false);
	const [savingChanges, setSavingChanges] = useState(false);

	const [idContent, setIDContent] = useState({ Content: null, Filename: "" });
	const [poaContent, setPOAContent] = useState({ Content: null, Filename: "" });

	useEffect(() => {
		async function getCustomerRegistrationDetails() {
			setDetails(null);
			setError("");

			var result = await store.CustomersService.GetCustomerRegistrationDetails(params.customerId);

			if(result) {
				if(result.Success) {
					setApproved(result.Data.Customer.RegistrationApproved)
					setDetails(result.Data);
				} else {
					setError("An error occurred retrieving the customer's registration details: " + result.Message);
				}
			} else {
				setError("An error occurred retrieving the customer's registration details. Please try again.");
			}
		}

		getCustomerRegistrationDetails();
	}, []);

	async function handleApproveRegistrationClicked() {
		setError("");
		setChangesSaved(false);
		setApprovingCustomer(true);
		var result = await store.CustomersService.ApproveCustomerRegistration(params.customerId);
		setApprovingCustomer(false);

		if(result) {
			if(result.Success) {
				setApproved(true);
			} else {
				setError("An error occurred attempting to approve the customer's registration: " + result.Message);
			}
		} else {
			setError("An error occurred attempting to approve the customer's registration. Please contact support.");
		}
	}

	async function handleDeclineRegistrationClicked() {
		setError("");
		setChangesSaved(false);
		setApprovingCustomer(true);
		var result = await store.CustomersService.DeclineCustomerRegistration(params.customerId);
		setApprovingCustomer(false);

		if(result) {
			if(result.Success) {
				setApproved(true);
				
			} else {
				setError("An error occurred attempting to Decline the customer's registration: " + result.Message);
			}
		} else {
			setError("An error occurred attempting to Decline the customer's registration. Please contact support.");
		}
	}

	function handleFileDownloadClicked(fileContent, filename) {
		const byteString = window.atob(fileContent);
		const arrayBuffer = new ArrayBuffer(fileContent.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
		  int8Array[i] = byteString.charCodeAt(i);
		}

		var blob = new Blob([int8Array]);
		saveAs(blob, filename);
	}

	function handleIDChanged(content, filename) {
		setIDContent({ Content: content, Filename: filename });
	}

	function handlePOAChanged(content, filename) {
		setPOAContent({ Content: content, Filename: filename });
	}

	async function handleFormSubmitClicked(values) {
		var registrationDetails = {
			Customer: values
		}

		if(idContent.Content) {
			registrationDetails.IDDocument = {
				FileName: idContent.Filename,
				Content: idContent.Content
			}
		}

		if(poaContent.Content) {
			registrationDetails.ProofOfAddress = {
				FileName: poaContent.Filename,
				Content: poaContent.Content
			}
		}

		setError("");
		setChangesSaved(false);
		setSavingChanges(true);

		var result = await store.CustomersService.UpdateCustomerRegistrationDetails(registrationDetails);
		setSavingChanges(false);

		if(result && result.Success) {
			setChangesSaved(true);
		} else {
			setError("An error occurred and the customer's details were not saved.");
		}
	}

	const validationSchema =
		yup.object({
			FirstName: yup.string().required("First Name is required."),
			Surname: yup.string().required("Surname is required."),
			Email: yup.string().required("Email is required.").email("Invalid Email Format"),
			CellphoneNumber: yup.string().required("Cellphone Number is required."),
			IDNumber: yup.string()
						.required("ID Number is required")
						.min(13, "Must contain 13 characters")
						.matches(/^\d+$/, "Invalid ID Number")
						.test({ message: "Invalid ID Number", test: (value) => validate(value) }) }
		).required();

	return 	<Row>
				<Col>
					<Card>
						<Card.Header>
							<h4>{ `User Registration Approval${ (approved ? " (Already Approved)" : "" )  }` }</h4>
						</Card.Header>
						<Card.Body>
							{
								details !== null &&
								<React.Fragment>
									<Formik initialValues={details.Customer} validationSchema={validationSchema} onSubmit={ handleFormSubmitClicked }>
									{({ errors, touched }) => (
										<FormikForm>
											<Form.Group as={Row} className="mb-2">
												<Form.Label column><b>First Name</b></Form.Label>
												<Col>
													<Field isInvalid={errors.FirstName && touched.FirstName } label="First Name" name="FirstName"  as={Form.Control}></Field>
													{
														touched.FirstName && errors.FirstName  &&
														<small className="text-danger">{ errors.FirstName }</small>
													}
												</Col>
											</Form.Group>
											<Form.Group as={Row} className="mb-2">
												<Form.Label column><b>Surname</b></Form.Label>
												<Col>
													<Field isInvalid={errors.Surname && touched.Surname }  label="Surname" name="Surname"  as={Form.Control}></Field>
													{
														touched.Surname && errors.Surname  &&
														<small className="text-danger">{ errors.Surname }</small>
													}
												</Col>
											</Form.Group>
											<Form.Group as={Row} className="mb-2">
												<Form.Label column><b>Email Address</b></Form.Label>
												<Col>
													<Field isInvalid={errors.Email && touched.Email }  label="Email Address" name="Email"  as={Form.Control}></Field>
													{
														touched.Email && errors.Email  &&
														<small className="text-danger">{ errors.Email }</small>
													}
												</Col>
											</Form.Group>
											<Form.Group as={Row} className="mb-2">
												<Form.Label column><b>Phone Number</b></Form.Label>
												<Col>
													<Field isInvalid={errors.CellphoneNumber && touched.CellphoneNumber }  label="Phone Number" name="CellphoneNumber"  as={Form.Control}></Field>
													{
														touched.CellphoneNumber && errors.CellphoneNumber  &&
														<small className="text-danger">{ errors.CellphoneNumber }</small>
													}
												</Col>
											</Form.Group>
											<Form.Group as={Row} className="mb-2 align-items-center">
												<Form.Label column xs={6}><b>Login Preference</b></Form.Label>
												<Col>
													<Field as={Form.Check} type="radio" value="Email" label="Email" name="EmailPreference"></Field>
												</Col>
												<Col>
													<Field as={Form.Check} type="radio" value="Cellphone" label="Cellphone" name="EmailPreference"></Field>
												</Col>
											</Form.Group>
											<Form.Group as={Row} className="mb-2 align-items-center">
												<Form.Label column xs={6}><b>Language Preference</b></Form.Label>
												<Col>
													<Field as={Form.Check} type="radio" value="EN" name="LanguagePreference" label="English"></Field>
												</Col>
												<Col>
													<Field as={Form.Check} type="radio" value="ZU" name="LanguagePreference" label="Zulu"></Field>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label column><b>ID Number</b></Form.Label>
												<Col>
													<Field isInvalid={errors.IDNumber && touched.IDNumber }  label="IDNumber" name="IDNumber" as={Form.Control}></Field>
													{
														touched.IDNumber && errors.IDNumber  &&
														<small className="text-danger">{ errors.IDNumber }</small>
													}
												</Col>
											</Form.Group>
											<hr/>
											<Form.Group as={Row}>
												<Form.Label column><b>Address</b></Form.Label>
												<Col>{ details.RadiusAddress.Address }</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label column><b>City</b></Form.Label>
												<Col>{ details.RadiusAddress.City }</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label column><b>State</b></Form.Label>
												<Col>{ details.RadiusAddress.State }</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label column><b>Zip</b></Form.Label>
												<Col>{ details.RadiusAddress.Zip }</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Form.Label column><b>Country</b></Form.Label>
												<Col>{ details.RadiusAddress.Country }</Col>
											</Form.Group>
											<hr/>
											<Row>
												<Col>
													<h6 style={{ cursor: "pointer" }} onClick={ e => handleFileDownloadClicked(details.IDDocument.Content, details.IDDocument.FileName)}>
														<FontAwesomeIcon icon={ faFileDownload }></FontAwesomeIcon> ID Document
													</h6>
													<div className="mt-2">
														<FileUpload onFileChanged={ handleIDChanged } file={idContent.Content} filename={idContent.Filename} name="id" description="Upload new ID"></FileUpload>
													</div>
												</Col>
												{/* <Col>
													<h6 style={{ cursor: "pointer" }} onClick={ e => handleFileDownloadClicked(details.ProofOfAddress.Content, details.ProofOfAddress.FileName)}>
														<FontAwesomeIcon icon={ faFileDownload }></FontAwesomeIcon> Proof Of Address
													</h6>
													<div className="mt-2">
														<FileUpload onFileChanged={ handlePOAChanged } file={poaContent.Content} filename={poaContent.Filename} name="poa" description="Upload new Proof of Address"></FileUpload>
													</div>
												</Col> */}
											</Row>
											<hr/>
											<Row>
												
												<Col className="d-flex">
													<Button style={{borderRadius:'12px'}} className="mr-3" onClick={ handleApproveRegistrationClicked } disabled={ approvingCustomer || approved }>Approve</Button>
													<Button style={{borderRadius:'12px' }} onClick={ handleDeclineRegistrationClicked } disabled={ approvingCustomer || approved } className="mr-3 btn-danger">Decline</Button>
												</Col>
												
												{
													error &&
													<Col>
														<p className="text-danger mt-2">{ error }</p>
													</Col>
												}
												{
													changesSaved &&
													<Col>
														<p className="text-success mt-2">Customer Details Updated Successfully</p>
													</Col>
												}
												<Col className="d-flex justify-content-end">
													<Button type="submit" style={{borderRadius:'12px'}} disabled={ approvingCustomer || savingChanges }>Save</Button>
												</Col>
											</Row>
										</FormikForm>)}
									</Formik>
								</React.Fragment>
							}
							<BusyIndicator show={ details === null && !error }></BusyIndicator>
						</Card.Body>
					</Card>
				</Col>
			</Row>
}