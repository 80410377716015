import APIBase from "./APIBase";

/**
 * @typedef User
 * @property {string} Id
 * @property {string} Name
 * @property {string} FirstName
 * @property {string} LastName
 * @property {string} Email
 * @property {string} Password
 * @property {string} ConfirmPassword
 */

/**
 * @typedef UsersService
 * @type {UsersService}
 */
export default class UsersService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Users", sessionStore);
    }

    /**
     * @param {string} email The email address of the user
     * @param {string} password The user's password
     * @returns { import("./APIBase").RequestResult<import("../Stores/SessionStore").Session> }
     */
    async Login(username, password) {
        return this.Post("login", { Username: username, Password: password });
    }

    /**
     * @param {User} user The User object to create
     * @returns { Promise<import("./APIBase").RequestResult<string>> }
     */
    async CreateUser(user) {
        return this.Post("CreateUser", user);
    }

    /**
     * @param {User} user The User object to update
     */
    async UpdateUser(user) {
        return this.Post("UpdateUser", user);
    }

    /**
     * @param {string} userId The Guid of the user to delete
     */
    async DeleteUser(userId) {
        return this.Post("DeleteUser", userId);
    }

    /**
     * @param {number} startIndex The 0 based start page index
     * @param {number} pageCount The total amount of results to return for the page
     * @param {string} search String containing a string to filter results by
     * @returns { import("./APIBase").EnumerableRequestResult<User> }
     */
    async GetUsers(startIndex, pageCount, search) {
        return this.Get(`GetUsers?startIndex=${startIndex}&pageCount=${pageCount}&search=${encodeURIComponent(search)}`);
    }

    /**
     * @param {string} userId The Id of the user to retrieve
     * @returns { Promise<import("./APIBase").RequestResult<User>> }
     */
    async GetUser(userId) {
        return this.Get(`GetUser?userId=${userId}`);
    }
}